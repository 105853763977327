import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/register/index.vue')
  },
  {
    path: '/login/bom',
    component: () => import('@/views/loginbom/index.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})
// router.beforeEach((to, from, next) => {
//   if(to.path === '/home/index' && ([100, 99, 98,97].includes(store.getters.userType))){
//     next({path:'/bom/index'})
//   }else{
//     next()
//   }

// });
router.beforeEach((to,from,next) => {
  const userRole = store.getters.userType;
  const requiredRoles = to.meta.roles || []
  if (requiredRoles && requiredRoles.length > 0 && !requiredRoles.includes(userRole)) {
    next({ path: '/404' })
    return;
  }
  if((to.path === '/home/index' || to.path === '/login') && store.getters.userType && ([100,99,98,97].includes(store.getters.userType))){
    store.dispatch("user/logout");
    to.path === '/home/index' ? next({path:'/login'}) : next()
    router.go(0);
  }else if(to.path === '/login/bom' && (store.getters.userType || store.getters.userType === 0) && !([100,99,98,97].includes(store.getters.userType))){
    store.dispatch("user/logout");
    next()
    router.go(0);
  }else{
    next()
  }
})
export function resetRouter () {
  const newRouter = router
  router.matcher = newRouter.matcher // 重置路由
}

export default router
