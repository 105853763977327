import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect', '/register','/login/bom'] // 没有重定向白名单
router.beforeEach(async (to, from, next) => {
  // 设置加载进度条
  NProgress.start()

  // 设置页面title
  document.title = getPageTitle(to.meta.title)
  const hasToken = getToken()
  if (hasToken) {
    if(to.path === '/login/bom'){
      store.dispatch("permission/setMduleName", to.meta.moduleName)
      if (([100, 99, 98,97].includes(store.getters.userType))){
        next({ path:'/bom/index' })
      }
    }
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      store.dispatch("permission/setMduleName", to.meta.moduleName)
      if (store.getters.userName) {
        next()
      } else {
        try {
          await store.dispatch("user/roleManage")
          next({ ...to, replace: true })
        } catch (error) {
          await store.dispatch('user/resetToken')
          Message.error(error || "无权操作")
          if(to.path === '/bom/index'){
            next(`/login/bom`)
          }else{
            next(`/login`)
          }
          // next(`/login`)
          NProgress.done()
        }
      }
    }
  } else {
    if(to.path === '/bom/index'){
      next(`/login/bom`)
    }
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
