import Layout from '@/layout'

const asyncRouter = [
  {
    path: '/home',
    component: Layout,
    redirect: '/home/index',
    meta: { moduleName: '首页' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/home'),
        name: 'Home',
        meta: { moduleName: '首页', roles: [0, 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 15, 16] }
      }
    ]
  },
  {
    path: '/equipment',
    component: Layout,
    redirect: '/equipment/index',
    meta: { moduleName: '设备管理' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment'),
        name: 'Equipment',
        meta: { moduleName: '设备管理', roles: [0, 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 15, 16] }
      }
    ]
  },
  {
    path: '/task',
    component: Layout,
    redirect: '/task/index',
    meta: { moduleName: '任务管理' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "task" */ '@/views/task'),
        name: 'Task',
        meta: { moduleName: '任务管理', roles: [0, 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 15, 16] }
      }
    ]
  },
  // {
  //   path: '/run',
  //   component: Layout,
  //   redirect: '/run/index',
  //   meta: { moduleName: '运行管控' },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "run" */ '@/views/run'),
  //       name: 'Run',
  //       meta: { moduleName: '运行管控', roles: [0, 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 15, 16] }
  //       // 所有角色都能查看
  //     }
  //   ]
  // },
  {
    path: '/model',
    component: Layout,
    redirect: '/model/index',
    meta: { moduleName: '型号管理' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "model" */ '@/views/model'),
        name: 'Model',
        meta: { moduleName: '型号管理', roles: [9, 10, 11, 15] }
        // 此接口只能零件厂商调用
        // 零件厂商只能将零件型号添加到自己名下
      }
    ]
  },
  {
    path: '/work',
    component: Layout,
    redirect: '/work/index',
    meta: { moduleName: '作业管理' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "work" */ '@/views/work'),
        name: 'Work',
        meta: { moduleName: '作业管理', roles: [0, 1, 2, 3, 5, 6, 8, 16] }
      }
    ]
  },
  {
    path: '/users',
    component: Layout,
    redirect: '/users/index',
    meta: { moduleName: '账号管理' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "Users" */ '@/views/users'),
        name: 'Users',
        meta: { moduleName: '账号管理', roles: [0] }
        // 此类接口只有管理员才能调用
      }
    ]
  },
  {
    path: '/driver',
    component: Layout,
    redirect: '/driver/index',
    meta: { moduleName: '团队管理' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "Driver" */ '@/views/driver'),
        name: 'Driver',
        meta: { moduleName: '团队管理', roles: [3, 5] } // 0去掉
        // 此类接口只有管理员、通航公司、设备服务商账号才能调用
      }
    ]
  },
  {
    path: '/driverApply',
    component: Layout,
    redirect: '/driverApply/index',
    meta: { moduleName: '入职管理', hideNav: true },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "DriverApply" */ '@/views/driverApply'),
        name: 'DriverApply',
        meta: { moduleName: '入职管理', roles: [0, 3, 5] }
        // 管理员、通航公司、设备服务商
      }
    ]
  },
  {
    path: '/flightLog',
    component: Layout,
    redirect: '/flightLog/index',
    meta: { moduleName: '日志管理' },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "FlightLog" */ '@/views/flightLog'),
        name: 'FlightLog',
        meta: { moduleName: '日志管理', roles: [0, 3, 5, 6] }
        // 管理员、通航公司、设备服务商
      }
    ]
  },
  {
    path: '/reports',
    component: Layout,
    redirect: '/reports/index',
    meta: { moduleName: '查看报表', hideNav: true},
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/reports'),
        name: 'Reports',
        meta: { moduleName: '查看报表', roles: [0, 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 15, 16] },
      }
    ]
  },
  {
    path: '/rtk',
    component: Layout,
    redirect: '/rtk/index',
    meta: { moduleName: 'RTK管理',},
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/rtk'),
        name: 'Rtk',
        meta: { moduleName: 'rtk管理', roles: [0] },
      }
    ]
  },
  {
    path: '/bom',
    component: Layout,
    redirect: '/bom/index',
    meta: { moduleName: 'bom管理',hideNav:true},
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "bomUser" */ '@/views/bom'),
        name: 'BOM',
        meta: { moduleName: 'bom管理', roles: [100,99,98,97] },
      }
    ]
  },
  {
    path: '/bom/user',
    component: Layout,
    redirect: '/bom/user/index',
    meta: { moduleName: '用户管理', hideNav: true},
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/bom/user'),
        name: '用户管理',
        meta: { moduleName: '用户管理', roles: [100] },
      }
    ]
  },
  {
    path: '/manu',
    component: Layout,
    redirect: '/manu/index',
    meta: { moduleName: '厂商管理'},
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/manu'),
        name: '厂商',
        meta: { moduleName: '厂商管理', roles: [0] },
      }
    ]
  },
  {
    path: '/invoice',
    component: Layout,
    redirect: '/invoice/index',
    meta: { moduleName: '发货管理', hideNav: true},
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/invoice'),
        name: 'Reports',
        meta: { moduleName: '发货管理', roles: [0, 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 15, 16,100,99,98,97] },
      }
    ]
  }, 
  {
    path: '*',
    component: Layout,
    redirect: '/NotFound/index',
    meta: { moduleName: '404',hideNav: true },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/NotFound'),
        name: 'Reports',
        meta: { moduleName: '404', roles: [0, 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 15, 16,100,99,98,97] },
      }
    ]
  }
]

export default asyncRouter
